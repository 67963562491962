import 'core-js/stable'
import 'regenerator-runtime/runtime'
import React from 'react'
import ReactDOM from 'react-dom/client'

import '@seed-design/stylesheet/global.css'
import '@stackflow/plugin-basic-ui/index.css'
import '@daangn/sprout-components-dialog/index.css'
import "@daangn/sprout-components-button/index.css";
import "@daangn/sprout-components-spinner/index.css"
import "@daangn/sprout-components-callout/index.css";
import '@daangn/transfer-identification-sdk/index.css'

import './styles/globalSeedDesign'

import './i18n'
import './index.css'

import App from './App'
import { initSentry } from './sentry'

initSentry()

const root = ReactDOM.createRoot(document.getElementById('root')!)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
